import { useEffect } from "react";
import useAppStore from "../data/store";

/** @format */
function MultiDigitInputLabel(props: {
  length: number;
  value: string;
  secret: boolean;
}) {
  const { length, value, secret = false } = props;
  const brand = useAppStore((state) => state.brand);
  let items = Array.from(Array(length).fill(0));
  let values = value.split("");
  useEffect(() => {
    console.log(values);
  }, [values]);
  return (
    <div>
      {items.map((v, i) => (
        <div
          key={`${i}`}
          className={`rounded-lg inline-block text-2xl text-center font-semibold select-none px-4 py-4 w-12 mr-1 focus:outline-none focus:ring-2 focus:ring-tint-secondary focus:ring-opacity-50 duration-300 ${
            (values[i] ?? "").length !== 0
              ? "bg-[#FFF200] text-black"
              : "bg-[#E2E2E4] text-black"
          } `}
          style={{ fontFamily: `${brand === "casa" ? "Anton" : "Open Sans"}` }}
          placeholder="-"
        >
          {values[i] !== undefined
            ? `${secret === true ? "*" : values[i]}`
            : "_"}
        </div>
      ))}
    </div>
  );
}

export default MultiDigitInputLabel;
