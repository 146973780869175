import { useCallback, useEffect, useState } from "react";
import "./App.css";
import Game from "./pages/Game";

import CheckUser, { UserInfo } from "./pages/CheckUser";
import { useAuthorization } from "./data/api";
import LoadingWheel from "./components/LoadingWheel";

import { Settings } from "luxon";
import CheckTicket from "./pages/CheckTicket";
import useAppStore from "./data/store";
import HeaderCasa from "./components/HeaderCasa";
import HeaderFortuna from "./components/HeaderFortuna";
Settings.defaultZone = "Europe/Bucharest";
// var DEV_MODE = (process.env.REACT_APP_DEV_MODE ?? false) === "true";

enum AppStatus {
  init,
  welcome,
  checkUser,
  checkTicket,
  restricted,
  alreadyPlayed,
  play,
  complete,
}

const Headers: { [key: string]: JSX.Element } = {
  casa: <HeaderCasa />,
  fortuna: <HeaderFortuna />,
  nobrand: <div />,
};

function App() {
  const setBrand = useAppStore((state) => state.setBrand);
  const brand = useAppStore((state) => state.brand);

  const [appState, setAppState] = useState<{
    status: AppStatus;
    user: string;
    ticket: boolean | undefined;
    jwt: string | undefined;
  }>({
    status: AppStatus.init,
    user: "",
    ticket: undefined,
    jwt: undefined,
  });

  const Header = Headers[brand];

  const handleClose = useCallback(() => {
    window.location.hash = "";
    window.location.reload();
    if (window && window.parent) {
      window.parent.postMessage("MSG_WHEEL_FINISHED", "*");
    }
  }, []);

  const { data: authorization } = useAuthorization(); //error: authorizationError

  const handleCheckUserComplete = (userInfo: UserInfo, token: string) => {
    console.debug(`handleCheckUserComplete ${token}`);
    const { user, restricted, hasPlayed } = userInfo;

    let newState = {
      user,
      status: AppStatus.play,
      ticket: undefined,
      jwt: token,
    };
    if (authorization?.settings.ticket.check) {
      newState.status = AppStatus.checkTicket;
    }
    if (restricted) {
      newState.status = AppStatus.restricted;
    }
    if (hasPlayed) {
      newState.status = AppStatus.alreadyPlayed;
    }
    setAppState(newState);
  };

  const handleCheckTicketComplete = useCallback(
    (token: string) => {
      console.debug(`handleCheckTicketComplete ${token}`);
      setAppState((state) => ({
        ...state,
        ticket: true,
        status: AppStatus.play,
        jwt: token,
      }));
    },
    [setAppState]
  );

  const handleStartCheckUser = () => {
    setAppState((state) => ({ ...state, status: AppStatus.checkUser }));
  };
  useEffect(() => {
    console.log("Authorization:");
    console.log(authorization);
    if (authorization) {
      setBrand(authorization.brand_id === "20" ? "casa" : "fortuna");
      setAppState((state) => ({
        ...state,
        status: AppStatus.welcome,
        jwt: authorization.token,
      }));
    }
    console.log("App started");
  }, [authorization, setBrand]);

  const postInteraction = useCallback(() => {
    console.log("posting interaction message to window");
    if (window && window.parent) {
      window.parent.postMessage("MSG_WHEEL_INTERACTION", "*");
    }
  }, []);
  useEffect(() => {
    document.addEventListener("click", postInteraction);
    // document.addEventListener('click', throttledUpdateidle);
    document.addEventListener("touchmove", postInteraction);
    document.addEventListener("onmousedown", postInteraction);
    document.addEventListener("mousewheel", postInteraction);
    document.addEventListener("touchstart", postInteraction);
    document.addEventListener("keydown", postInteraction);

    return () => {
      document.removeEventListener("click", postInteraction);
      document.removeEventListener("touchmove", postInteraction);
      document.removeEventListener("onmousedown", postInteraction);
      document.removeEventListener("mousewheel", postInteraction);
      document.removeEventListener("touchstart", postInteraction);
      document.removeEventListener("keydown", postInteraction);
    };
  }, [postInteraction]);

  if (appState.status === AppStatus.init) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            backgroundColor: "black",
            marginTop: "5vh",
            width: "90vh",
            height: "90vh",
            borderRadius: "50%",
            zIndex: 2,
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingWheel />
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      {Header}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        {appState.status === AppStatus.checkUser && (
          <CheckUser
            onComplete={handleCheckUserComplete}
            brand={authorization?.brand_id ?? ""}
            token={appState.jwt ?? "-"}
            ak={authorization?.ak ?? ""}
            skipSuccess={authorization?.settings.ticket.check === true}
          />
        )}
        {appState.status === AppStatus.checkTicket && (
          <CheckTicket
            onComplete={handleCheckTicketComplete}
            jwt={appState.jwt ?? "-"}
            user={appState.user}
            // ak={authorization?.ak ?? ""}
          />
        )}

        {appState.status === AppStatus.play && (
          <Game
            onComplete={handleClose}
            prizes={authorization!.prizes}
            jwt={appState.jwt ?? "-"}
          />
        )}

        {appState.status === AppStatus.welcome && (
          <Game
            onComplete={handleStartCheckUser}
            idle={true}
            prizes={authorization!.prizes}
            jwt={"-"}
          />
        )}
      </div>
    </div>
  );
}

export default App;
