import { useEffect, useState } from "react";
import useAppStore from "../data/store";
import casa_background from "../img/SpinButton.png";
import fortuna_background from "../img/fortuna-spin-button.png";

const SpinButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [isTapped, setIsTapped] = useState(false);
  const brand = useAppStore((state) => state.brand);
  const background = brand === "casa" ? casa_background : fortuna_background;

  useEffect(() => {
    console.log(isTapped);
  }, [isTapped]);

  return (
    <div
      onMouseDown={() => setIsTapped(true)}
      onMouseUp={() => setIsTapped(false)}
      onClick={onClick}
      className="fadeout"
      style={{
        display: "inline-flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        fontFamily: `${brand === "casa" ? "Anton" : "Open Sans"}`,
        fontWeight: brand === "casa" ? 400 : 800,
        fontSize: brand === "casa" ? 42 : 35,
        fontStyle: `normal`,

        color: "#272400",
        width: "152px",
        height: "152px",
        boxShadow: "0px 6px 0px #827700",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          display: "inline-flex",
          width: "152px",
          height: "152px",
          background: `url(${background}) `,
          backgroundSize: "100% 100%",
          transform: `rotate(${isTapped ? 90 : 0}deg)`,
          transition: "0.2s",
        }}
      />
      <div
        style={{
          display: "inline-block",
          zIndex: "2",
          marginRight: brand === "fortuna" ? 2 : 0,
          marginTop: brand === "fortuna" ? 2 : 0,
          transform: `${brand === "fortuna" ? " skewY(-6deg)" : ""}`,
        }}
      >
        SPIN
      </div>
    </div>
  );
};
export default SpinButton;
