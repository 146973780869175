import { useCallback, useEffect, useState } from "react";

import NumericKeyboard from "../components/NumericKeyboard";
import MultiDigitInputLabel from "../components/SecretInput";
import ToggleVisibilityButton from "../components/ToggleVisibilityButton";

import { sleep } from "../components/util";
import { checkCNP, checkRNUser } from "../data/api";
import isCNP from "../data/cnp";
import useAppStore from "../data/store";
import CountdownMessage from "./CountdownMessage";
import ErrorMessage from "./ErrorMessage";
import InfoMessage from "./InfoMessage";
import SuccessMessage from "./SuccessMessage";

let preloadedCNP = document.location.hash;
if (preloadedCNP.startsWith("#cnp=") && preloadedCNP.length === 18) {
  preloadedCNP = preloadedCNP.slice(5);
  console.log(`preloadedCNP found ${preloadedCNP}`);
} else {
  console.log(`preloadedCNP not found or invalid ${preloadedCNP}`);
  preloadedCNP = "";
}

export interface UserInfo {
  user: string;
  restricted: boolean;
  hasPlayed: boolean;
}
const CheckUser: React.FC<{
  ak: string;
  token: string;
  brand: string;
  onComplete: (user: UserInfo, token: string) => void;
  skipSuccess: boolean;
}> = ({ onComplete, token, brand, ak, skipSuccess }) => {
  const [cnpVisible, setCnpVisible] = useState(false);
  const [isChecking, setIsChecking] = useState(0);
  const [endDate, setEndDate] = useState(new Date());
  const [formId, setFormID] = useState(0);
  const [data, setData] = useState({
    cnp: preloadedCNP,
    error: true,
    message: "",
  });
  // const [inputText, setInputText] = useState("5001021511612");
  const resetForm = useCallback(() => {
    setData((data) => ({
      cnp: "",
      error: true,
      message: "Introduceți toate cele 13 cifre",
    }));
    setFormID((id) => id + 1);
    setIsChecking(0);
  }, []);

  const brandName = useAppStore((state) => state.brand);
  const verifyCNP = useCallback(
    async (cnp: string, brand: string, ak: string) => {
      setIsChecking(1);
      const { result, user } = await checkCNP(cnp, brand, ak);
      await sleep(400);
      switch (result) {
        case 1:
          let rnUserStatus = await checkRNUser(user!, token);
          console.log(`${cnp} ${rnUserStatus.result}`);
          switch (rnUserStatus.result) {
            case 1: //can play
              console.log("rn status 1, can play");
              console.log(rnUserStatus);
              if (!skipSuccess) {
                setIsChecking(2);
                await sleep(1500);
              }
              onComplete(
                { user: user!, restricted: false, hasPlayed: false },
                rnUserStatus.jwt!
              );
              return;
            case 0: // played
              console.log("rn status 0, already played");
              setEndDate(rnUserStatus.end!);
              setIsChecking(4);
              return;
            case -1: // error
              console.log("rn status -1, error");
              setData((data) => ({
                ...data,
                error: true,
                message: "Problema de verificare.",
              }));

              setIsChecking(3);
              setTimeout(resetForm, 2000);
              return;
          }
          return;
        case 0:
          console.log("f status 0 not registered");
          setData((data) => ({
            ...data,
            error: true,
            message: "Client neînregistrat!",
          }));
          setIsChecking(3);
          return;
        case -1:
          console.log("f status 0 restricted");
          setData((data) => ({
            ...data,
            error: true,
            message: "Client restricționat!",
          }));
          setIsChecking(3);
          return;

        case -200:
          console.log("f status check error");
          setData((data) => ({
            ...data,
            error: true,
            message: "Problema la verificare. Incercati din nou.",
          }));
          setIsChecking(3);
          return;

        case -100:
          console.log("if status can't check cnp");
          setData((data) => ({
            cnp: "",
            error: true,
            message: "CNP-ul nu poate fi validat",
          }));
          setIsChecking(3);
          return;
      }
      setIsChecking(0);
    },
    [onComplete, resetForm, skipSuccess, token]
  );

  const handleChangeValue = useCallback(
    async (value: string) => {
      console.debug(`handleChangeValue ${value}`);

      let error = !isCNP(value);
      let message = "CNP-ul nu este valid";
      if (value.length < 13) {
        message = "Introduceți toate cele 13 cifre";
      }

      console.log(`cnp: ${value} error: ${error}`);
      setData({
        cnp: value,
        error,
        message: error ? message : "",
      });

      // setInputText(
      //   value
      //     .split("")
      //     .map((v, i) => "*")
      //     .join("")
      // );
      if (!error) {
        console.log(`await verifyCNP...`);
        await verifyCNP(value, brand, ak);
      }
    },
    [ak, brand, verifyCNP]
  );

  useEffect(() => {
    console.debug(`data: ${data.cnp}`);
  }, [data]);

  useEffect(() => {
    console.debug({ endDate });
  }, [endDate]);
  return (
    <div className={`fullscreen  modal z-[999999] app_background_${brandName}`}>
      {isChecking === 4 && (
        <CountdownMessage
          end={endDate}
          title="Timp rămas până la următoarea rotire"
          style={isChecking !== 4 ? { display: "none" } : {}}
          onClose={resetForm}
        />
      )}
      <ErrorMessage
        title="Eroare verificare"
        subtitle={data.message}
        style={isChecking !== 3 ? { display: "none" } : {}}
        onClose={resetForm}
      />
      <InfoMessage
        title="Verificare.."
        style={isChecking !== 1 ? { display: "none" } : {}}
      />
      <SuccessMessage
        title="Aveți o rotire gratuită"
        style={isChecking !== 2 ? { display: "none" } : {}}
      />
      <div
        className={`${isChecking !== 0 ? "hidden" : "modal"}`}
        style={{ minWidth: 400 }}
      >
        <div className="form white">
          <div
            className="label mb-4 relative w-full"
            style={{
              fontFamily: `${brandName === "casa" ? "Anton" : "Open Sans"}`,
              fontWeight: `${brandName === "casa" ? "400" : "800"}`,
            }}
          >
            Introduceți CNP{" "}
            <span className="absolute right-4">
              <ToggleVisibilityButton
                visible={cnpVisible}
                onClick={() => {
                  setCnpVisible((value) => !value);
                }}
              />
            </span>
          </div>
          {/* <div
            className={`input ${data.error === true ? "error" : ""}`}
            style={{ marginTop: 24, marginBottom: 8 }}
          >
            {inputText}
          </div> */}
          <MultiDigitInputLabel
            length={13}
            value={data.cnp}
            secret={!cnpVisible}
          />
          <div
            className={`comment mt-4  ${data.error === true ? "error" : ""}`}
          >
            {data.error ? data.message : "Se verifică datele.."}
          </div>
        </div>

        <div className="form dark" style={{ marginTop: 24, width: "400px" }}>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NumericKeyboard
              key={formId}
              initialValue={
                preloadedCNP !== "" ? Number(preloadedCNP) : undefined
              }
              maxLength={13}
              onChange={handleChangeValue}
              title=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckUser;
