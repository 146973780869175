import React from "react";

import useAppStore from "../data/store";

const SuccessMessage: React.FC<{
  title: string;
  style?: React.CSSProperties;
}> = (props) => {
  const { title, style } = props;
  const brand = useAppStore((state) => state.brand);
  return (
    <div
      style={{
        fontFamily: `${brand === "casa" ? "Anton" : "Open Sans"}`,
        fontWeight: `${brand === "casa" ? "400" : "800"}`,
        fontSize: "36px",
        lineHeight: "54px",
        color: "#000000",
        padding: "8px",
        boxShadow: "white 0px -4px 45px",
        position: "relative",
        borderRadius: "24px",
        background: "#00E068",
        ...style,
      }}
    >
      <div
        style={{
          border: "8px solid #00E068",
          padding: "32px",
          minWidth: "500px",
          maxWidth: "800px",
          borderRadius: 20,
          display: "inline-flex",
          justifyContent: "center",
          boxShadow:
            "rgba(0,0,0,0.05) 0px 0px 50px inset, rgba(0,0,0,0.1) 0px 0px 16px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;

/*   <MessageContainer style={{ background: "#212121" }}>
        <ErrorMessage
          title="Eroare"
          subtitle="Problema la verificare "
          onClose={() => {}}
        />
      </MessageContainer>
      */
