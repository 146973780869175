import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ReactComponent as BackSpace } from "../img/back-24px.svg";

const Key: React.FC<
  PropsWithChildren & {
    value: string;
    className?: string;
    onClick: (e: React.SyntheticEvent<HTMLElement>) => void;
  }
> = (props) => {
  const { children, value, onClick, className } = props;
  const [active, setActive] = React.useState(false);

  const label = children || value;

  const clickHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    // setActive(true);
    onClick(e);
  };

  return (
    <div
      key={value}
      data-key={value}
      className={`key min-w-64 h-64 ${className || ""} ${
        active ? "key-active" : ""
      }`}
      onClick={clickHandler}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onMouseLeave={() => setActive(false)}
      onTouchStart={() => setActive(true)}
      onTouchEnd={() => setActive(false)}
    >
      {label}
    </div>
  );
};

export interface NumericKeyboardProps {
  onCancel?: () => void;
  onChange: (value: string) => void;
  initialValue?: number;
  title: string;
  maxLength?: number;
}

const NumericKeyboard: React.FC<NumericKeyboardProps> = ({
  initialValue,
  onCancel,
  onChange,
  maxLength,
  title,
}) => {
  const [tempValue, setTempValue] = useState(`${initialValue ?? ""}`);

  const digitClickHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    const key = e.currentTarget.dataset.key;
    addKey(key!);
  };

  const addKey = useCallback(
    (key: string) => {
      if (maxLength !== undefined && tempValue.length === maxLength) {
        console.log(`text has already max length ${maxLength}`);
        return;
      }
      const newValue = `${tempValue}${key}`;
      let numericValue = newValue;

      if (
        numericValue.length > 0 &&
        numericValue.charAt(numericValue.length - 1) === "."
      ) {
        numericValue = `${numericValue}0`;
      }
      if (!isNaN(Number(numericValue))) {
        setTempValue(newValue);
      } else {
      }
    },
    [maxLength, tempValue]
  );

  // const periodHandler = (e: React.SyntheticEvent<HTMLElement>) => {
  //   if (tempValue.indexOf(".") < 0) {
  //     setTempValue(`${tempValue}.`);
  //   }
  // };
  const handleBackspace = useCallback(() => {
    const newValue = tempValue.slice(0, -1);
    let numericValue = newValue;
    if (
      numericValue.length > 0 &&
      numericValue.charAt(numericValue.length - 1) === "."
    ) {
      numericValue = `${numericValue}0`;
    }
    if (!isNaN(Number(numericValue))) {
      setTempValue(newValue);
    } else {
    }
  }, [tempValue]);
  const backspaceHandler = (e: React.SyntheticEvent<HTMLElement>) => {
    handleBackspace();
  };

  const handleKeyDown: (ev: KeyboardEvent) => void = useCallback(
    (ev) => {
      let key = ev.key;

      if (
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf(key) >= 0
      ) {
        addKey(key);
      }
      if (key === "Backspace") {
        handleBackspace();
      }
    },
    [addKey, handleBackspace]
  );

  useEffect(() => {
    // console.log(`TEMP VALUE CHANGED ${tempValue}`);
    onChange(tempValue);
  }, [tempValue, onChange]);

  useEffect(() => {
    console.log("Keyboard rerender");
    const callback = (e: KeyboardEvent) => {
      handleKeyDown(e);
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [handleKeyDown]);
  return (
    <div className="inline-grid grid-cols-3 grid-rows-4 gap-y-3 gap-x-1 w-full">
      <Key value="7" data-key={7} onClick={digitClickHandler} />
      <Key value="8" data-key={8} onClick={digitClickHandler} />
      <Key value="9" data-key={9} onClick={digitClickHandler} />
      <Key value="4" data-key={4} onClick={digitClickHandler} />
      <Key value="5" data-key={5} onClick={digitClickHandler} />
      <Key value="6" data-key={6} onClick={digitClickHandler} />
      <Key value="1" data-key={1} onClick={digitClickHandler} />
      <Key value="2" data-key={2} onClick={digitClickHandler} />
      <Key value="3" data-key={3} onClick={digitClickHandler} />
      <Key
        className="col-span-2"
        value="0"
        data-key={0}
        onClick={digitClickHandler}
      />
      {/* <Key className="" value="." onClick={periodHandler}
      oidusodusoufido /> */}
      <Key value="<" className="key key-dark" onClick={backspaceHandler}>
        <BackSpace />
      </Key>
    </div>
  );
};

export default NumericKeyboard;
