import React, { useCallback, useEffect, useState } from "react";
import close_button from "../img/close_round_x.png";
import { DateTime } from "luxon";

const CountdownMessage: React.FC<{
  title: string;
  style?: React.CSSProperties;
  end: Date;
  onClose: () => void;
}> = (props) => {
  const { onClose, title, style, end } = props;
  const [remainingD, setRemainingD] = useState("00");
  const [remainingS, setRemainingS] = useState("00:00:00");

  const updateTimer = useCallback(() => {
    let tomorrow = DateTime.fromJSDate(end ?? new Date());
    let diff = tomorrow.diffNow();
    let rs = diff.toFormat("dd hh:mm:ss");
    setRemainingS(`${rs}`.slice(3));
    setRemainingD(`${rs}`.slice(0, 2));
  }, [end]);

  useEffect(() => {
    setInterval(updateTimer, 500);
  }, [updateTimer]);

  return (
    <div
      style={{
        fontFamily: "Anton",
        fontSize: "42px",
        color: "#232323",
        padding: "8px",
        boxShadow: "#ffec00 0px -4px 45px",
        position: "relative",
        borderRadius: "24px",
        background: "#ffec00",
        ...style,
      }}
    >
      <div
        style={{
          border: "8px solid #ffec00",
          padding: "32px 64px 64px 64px",
          minWidth: "500px",
          maxWidth: "800px",
          borderRadius: 20,
          display: "inline-flex",
          justifyContent: "center",
          boxShadow:
            "rgba(0,0,0,0.05) 0px 0px 50px inset, rgba(0,0,0,0.1) 0px 0px 16px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {title}
          </div>
          <div
            style={{
              display: "inline-block",
              fontSize: 52,
              fontFamily: "Open Sans",
              paddingTop: "16px",
              textAlign: "center",
            }}
          >
            {remainingD !== "00" && (
              <>
                {remainingD.split("").map((v, i) => (
                  <div
                    key={`d${i}${v}`}
                    style={{
                      display: "inline-block",
                      color: "#fff",
                      textAlign: "center",
                      backgroundColor: "#232323",
                      borderRadius: "4px",
                      margin: "1px",
                      width: "48px",
                      height: "64px",
                      fontSize: "40px",
                      fontWeight: "bold",
                      lineHeight: "62px",
                    }}
                  >
                    {v}
                  </div>
                ))}
                <span> </span>
              </>
            )}
            {remainingS.split("").map((v, i) => (
              <div
                key={`${i}${v}`}
                style={
                  v !== ":"
                    ? {
                        display: "inline-block",
                        color: "#fff",
                        textAlign: "center",
                        backgroundColor: "#232323",
                        borderRadius: "4px",
                        margin: "1px",
                        width: "48px",
                        height: "64px",
                        fontSize: "40px",
                        fontWeight: "bold",
                        lineHeight: "62px",
                      }
                    : {
                        display: "inline-block",
                        textAlign: "center",
                        fontSize: "40px",
                        margin: "8px",
                        fontWeight: "bold",
                        lineHeight: "48px",
                      }
                }
              >
                {v}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "inline-block",
          width: 72,
          height: 72,
          position: "absolute",
          bottom: -36,
          right: "calc(50% - 36px)",
        }}
      >
        <img onClick={onClose} src={close_button} alt="close" width="100%" />
      </div>
    </div>
  );
};

export default CountdownMessage;

/*   <MessageContainer style={{ background: "#212121" }}>
        <ErrorMessage
          title="Eroare"
          subtitle="Problema la verificare "
          onClose={() => {}}
        />
      </MessageContainer>
      */
