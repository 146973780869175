import React, { useEffect, useRef } from "react";

import center_img from "../img/Srednji.png";
import exclusion2 from "../img/Exclusion 2.png";
import exclusion3 from "../img/Exclusion 3.png";
// import pin from "../img/Union 1.png";
import dot from "../img/Component 6 – 71.png";

function LoadingWheel() {
  const wheel = useRef<HTMLDivElement>(null);
  const dots = useRef<HTMLDivElement>(null);
  // const pinRef = useRef<HTMLImageElement>(null);

  let slices = 12;
  const items = new Array(slices).fill("0").map((i) => "   ? ");
  items[0] = "⏳";

  let angle = (2 * Math.PI) / slices;
  // let angleDeg = 360 / slices;
  let wheel_width = 400;
  let slice_width = wheel_width * Math.tan(angle / 2);

  function startSpinning() {
    wheel.current?.animate(
      [{ transform: `rotate(${0}deg)` }, { transform: `rotate(${360}deg)` }],
      {
        duration: 1000,
        easing: "ease-in-out",
        fill: "forwards",
        iterations: Infinity,
      }
    );
    dots.current?.animate(
      [{ transform: `rotate(${0}deg)` }, { transform: `rotate(${360}deg)` }],
      {
        duration: 1000,
        easing: "ease-in-out",
        fill: "forwards",
        iterations: Infinity,
      }
    );
  }

  const colors: { b: string; l: string }[] = items.map((_, i) =>
    i % 2 === 0 ? { b: "#FFF200", l: "black" } : { b: "#fff", l: "white" }
  );
  colors[0] = { b: "#212121", l: "white" };

  useEffect(() => {
    startSpinning();
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: `${wheel_width + 100}px`,
        height: `${wheel_width + 100}px`,
        top: 0,
        filter: "saturate(0)",
      }}
    >
      <div
        ref={wheel}
        style={{
          position: "relative",
          left: 50,
          top: 50,
          width: `${wheel_width}px`,
          height: `${wheel_width}px`,
          borderRadius: "50%",
          overflow: "hidden",
          willChange: "transform",
          transform: "translateZ(0)",
        }}
      >
        {items.map((v, i) => (
          <Slice
            key={`${i} ${v}`}
            radius={wheel_width / 2}
            text={v}
            width={(slice_width / wheel_width) * 100}
            rotation={((angle * 360) / Math.PI / 2) * i}
            color={colors[i].b}
            textColor={colors[i].l}
            index={i}
          />
        ))}
        {items.map((v, i) => (
          <div
            key={`z ${i} ${v}`}
            className="triangle-shadow"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: `${wheel_width}px`,
              height: `${wheel_width}px`,
              display: "inline-flex",
              zIndex: 900 + i,
              transform: `rotate(${
                ((angle * 360) / Math.PI / 2) * (0.5 + i)
              }deg)`,
              background:
                "conic-gradient(rgba(200,160,160, 0.6), rgba(160,100,100, 0.15) 1%, rgba(160,100,100, 0.0) 1.5%, rgba(160,100,100, 0.0) 98.5%,  rgba(160,100,100, 0.1) 99.6%, rgba(200,160,160,0.2) 100%)",
              clipPath: "polygon(40% 0%, 60% 0%, 50% 50%)",
              mixBlendMode: "screen",
            }}
          />
        ))}
      </div>
      <img
        src={exclusion2}
        alt="center"
        style={{
          position: "absolute",
          width: `calc(100% + 42px)`,
          height: `calc(100% + 42px)`,
          left: -21,
          top: -23,
          zIndex: 3000,
        }}
      />
      <img
        src={exclusion3}
        alt="center"
        style={{
          position: "absolute",
          width: `calc(100% + 76px)`,
          height: `calc(100% + 76px)`,
          left: -38,
          top: -40,
          zIndex: 4000,
        }}
      />
      <div
        ref={dots}
        style={{
          position: "absolute",
          zIndex: 3500,
          left: 25,
          top: 25,
          width: wheel_width + 50,
          height: wheel_width + 50,
          display: "inline-block",
        }}
      >
        {items.map((v, i) => (
          <img
            src={dot}
            key={`dot ${i}`}
            alt={`dot`}
            style={{
              display: "inline-block",
              position: "absolute",
              width: 50,
              height: 50,
              left:
                wheel_width * 0.5 +
                wheel_width * 0.45 * Math.cos(-Math.PI / 2 + angle * (i - 0.5)),
              top:
                wheel_width * 0.5 +
                wheel_width * 0.45 * Math.sin(-Math.PI / 2 + angle * (i - 0.5)),
              zIndex: 3500 + i,
            }}
          />
        ))}
      </div>
      {/* <img
        src={pin}
        ref={pinRef}
        alt="pin"
        style={{
          position: "absolute",
          width: 80,
          height: 120,
          left: wheel_width * 0.5 + 8,
          top: -32,
          zIndex: 4002,
          //  transform: `rotate(${pin_rotate_deg}deg)`,
          transformOrigin: `${40}px ${41}px`,
        }}
      /> */}
      <img
        src={center_img}
        alt="center"
        style={{
          position: "absolute",
          width: 160,
          height: 160,
          left: wheel_width / 2 + 50 - 80,
          top: wheel_width / 2 + 50 - 80,
          zIndex: 3000,
        }}
      />
    </div>
  );
}
export default LoadingWheel;

interface SliceProps {
  radius: number;
  width: number;
  rotation: number;
  color: string;
  text: string;
  textColor: string;
  index: number;
}
function Slice({
  width,
  rotation,
  color,
  text,
  textColor,
  index,
  radius,
}: SliceProps) {
  let clip = [50 - width / 2, 50 + width / 2];
  let rotationAngle = rotation;
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        // filter: "drop-shadow(0px 0px 10px white)",
        zIndex: 100 + index,
      }}
    >
      <div
        style={{
          backgroundColor: color,
          clipPath: `polygon(${clip[0]}% 0%, ${clip[1]}% 0%, 50% 50%)`,
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          position: "absolute",
          left: 0,
          top: 0,
          display: "inline-flex",
          alignItems: "flex-start",
          transform: `rotate(${rotationAngle}deg)`,
        }}
      >
        <div
          style={{
            paddingTop: "20px",
            paddingRight: "4px",
            fontFamily: "Anton",
            fontSize: "24px",
            textAlign: "left",
            lineHeight: "24px",
            zIndex: 20,
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
            height: "40%",
            color: textColor,
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
}
