import React from "react";
import close_button from "../img/close_round_x.png";
import useAppStore from "../data/store";

const ErrorMessage: React.FC<{
  title: string;
  subtitle: string;
  style?: React.CSSProperties;
  onClose: () => void;
}> = (props) => {
  const { onClose, title, subtitle, style } = props;
  const brand = useAppStore((state) => state.brand);
  return (
    <div
      style={{
        fontFamily: `${brand === "casa" ? "Anton" : "Open Sans"}`,
        fontSize: "52px",
        color: "#fff",
        padding: "8px",
        boxShadow: "#ED1C24 0px -4px 45px",
        position: "relative",
        borderRadius: "24px",
        background: "#ED1C24",
        ...style,
      }}
    >
      <div
        style={{
          border: "8px solid #ED1C24",
          padding: "32px 64px 64px 64px",
          minWidth: "500px",
          maxWidth: "800px",
          borderRadius: 20,
          display: "inline-flex",
          justifyContent: "center",
          boxShadow:
            "rgba(0,0,0,0.05) 0px 0px 50px inset, rgba(0,0,0,0.1) 0px 0px 16px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {title}
          </div>
          <div
            style={{
              display: "inline-block",
              fontSize: 22,
              fontFamily: "Open Sans",
              paddingTop: "24px",
            }}
          >
            {subtitle}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "inline-block",
          width: 72,
          height: 72,
          position: "absolute",
          bottom: -36,
          right: "calc(50% - 36px)",
        }}
      >
        <img onClick={onClose} src={close_button} alt="close" width="100%" />
      </div>
    </div>
  );
};

export default ErrorMessage;

/*   <MessageContainer style={{ background: "#212121" }}>
        <ErrorMessage
          title="Eroare"
          subtitle="Problema la verificare "
          onClose={() => {}}
        />
      </MessageContainer>
      */
