import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ReactComponent as BackSpace } from "../img/back-24px.svg";
import { ReactComponent as ShiftIcon } from "../img/shift-24px.svg";
const SHIFT_KEY = "±";

const DIGITS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
const LETTERS = [
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
  ["±", "z", "x", "c", "v", "b", "n", "m"],
];

const Key: React.FC<
  PropsWithChildren & {
    value: string;
    className?: string;
    onClick: (e: React.MouseEvent) => void;
  }
> = (props) => {
  const { children, value, onClick, className } = props;
  const [active, setActive] = React.useState(false);

  const label = children || value;

  const clickHandler = (e: React.MouseEvent) => {
    // setActive(true);
    onClick(e);
  };

  return (
    <div
      key={value}
      data-key={value}
      className={`key min-w-64 h-64 ${className || ""} ${
        active ? "key-active" : ""
      }`}
      onClick={clickHandler}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onMouseLeave={() => setActive(false)}
      onTouchStart={() => setActive(true)}
      onTouchEnd={() => setActive(false)}
    >
      {label}
    </div>
  );
};

export interface KeyboardProps {
  onCancel?: () => void;
  onPress: (value: string) => void;
  onBackspace: () => void;
  initialValue?: number;
  title: string;
  maxLength?: number;
  caps?: boolean;
}

const FullKeyboard: React.FC<KeyboardProps> = ({
  initialValue,
  onCancel,
  onPress,
  onBackspace,
  maxLength,
  title,
  caps: initialCaps = false,
}) => {
  // const [tempValue, setTempValue] = useState(`${initialValue ?? ""}`);
  const [caps, setCaps] = useState(initialCaps);

  const handleKey = useCallback(
    (key: string) => {
      if (key[0] === SHIFT_KEY) {
        setCaps(!caps);
        return;
      }
      onPress(caps ? key.toUpperCase() : key);
    },
    [onPress, caps]
  );

  const backspaceHandler = (e: React.MouseEvent) => {
    onBackspace();
  };

  const clickHandler = (e: React.MouseEvent) => {
    const key: string = e.currentTarget.getAttribute("data-key")!;
    handleKey(key);
  };

  // physical keyboard event
  const handleKeyDown: (ev: KeyboardEvent) => void = useCallback(
    (ev) => {
      const key: string = ev.key;
      console.log(key);

      if (key === "Shift") {
        setCaps((caps) => !caps);
        return;
      }
      if (key === "Backspace") {
        onBackspace();
        return;
      }
      if (key.length > 1) {
        return;
      }

      let code = key.charCodeAt(0);
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) // lower alpha (a-z)
      ) {
        return;
      }
      console.log(code);
      console.log("good");
      onPress(key);
    },
    [onPress, onBackspace, setCaps]
  );

  useEffect(() => {
    console.log("Keyboard rerender");
    const callback = (e: KeyboardEvent) => {
      handleKeyDown(e);
    };
    document.addEventListener("keydown", callback);
    // fix me: keyUp SHIFT
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [handleKeyDown]);
  return (
    <div className="keyboard">
      <div className="keyboard-digits">
        {DIGITS.map((d) => (
          <Key value={d} key={d} onClick={clickHandler} className={"key"} />
        ))}
      </div>

      {LETTERS.map((lrow, lrix) => (
        <div className="keyboard-letters" key={lrix}>
          {lrow.map((l) => (
            <Key
              key={l}
              value={l}
              onClick={clickHandler}
              className={`${
                l[0] === SHIFT_KEY ? "key key-dark w-24" : "key !min-w-24"
              }`}
            >
              {l[0] === SHIFT_KEY ? <ShiftIcon /> : caps ? l.toUpperCase() : l}
            </Key>
          ))}
          {lrix === 2 && (
            <Key
              value="<"
              className="key key-dark w-24"
              onClick={backspaceHandler}
            >
              <BackSpace className="inline-block" />
            </Key>
          )}
        </div>
      ))}
    </div>
  );
};

export default FullKeyboard;
