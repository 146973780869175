import { useCallback, useEffect, useState } from "react";
import FullKeyboard from "../components/FullKeyboard";
import NumericKeyboard from "../components/NumericKeyboard";
import { sleep } from "../components/util";
import { serverCheckTicket } from "../data/api";
import { CheckTicketMessages } from "./CheckTicket";
import ErrorMessage from "./ErrorMessage";
import InfoMessage from "./InfoMessage";
import SuccessMessage from "./SuccessMessage";

enum CheckTicketStep {
  code,
  pin,
  check,
  success,
  error,
}
function CheckSBLottoTicket({
  onComplete,
  jwt,
}: {
  onComplete: (token: string) => void;
  jwt: string;
  user: string;
}) {
  const [text, setText] = useState<string | undefined>("");
  const [pin, setPin] = useState<string | undefined>("");
  const [message] = useState<string>(CheckTicketMessages.sblotto);
  const [step, setStep] = useState<CheckTicketStep>(CheckTicketStep.code);
  const [ticketError, setTicketError] = useState<string>("");

  const handleChangeNumKeyboard = useCallback(
    (value: string) => {
      setPin(value);
    },
    [setPin]
  );
  const TEXT_MAX_LENGTH = 16;

  const handleFullKeyboardKey = useCallback(
    (key: string) => {
      setText((text) =>
        (text ?? "").length < TEXT_MAX_LENGTH ? `${text ?? ""}${key}` : text
      );
    },
    [setText]
  );

  const handleFullKeyboardBackspace = useCallback(() => {
    setText((text) =>
      (text ?? "").length > 0 ? text?.slice(0, text.length - 1) : undefined
    );
  }, []);

  const textIsValid = (text: string | undefined) => {
    let length = text?.length;
    if (length === 6) {
      return true;
    } else if (!isNaN(Number(text)) && length === 16) {
      return true;
    } else {
      return false;
    }
  };
  const handleNext = useCallback(() => {
    switch (step) {
      case CheckTicketStep.code:
        if (pin?.length === 4) {
          setStep(CheckTicketStep.check);
        } else {
          setStep(CheckTicketStep.pin);
        }
        break;
      case CheckTicketStep.pin:
        if (textIsValid(text)) {
          setStep(CheckTicketStep.check);
        }
        break;
    }
  }, [text, pin, step]);

  // Check the ticket on server and receive a JWT to use on spin.
  const validateTicket = useCallback(
    async (text: string, pin: string) => {
      let result = await serverCheckTicket(text, pin, jwt);
      if (result.error !== undefined) {
        setTicketError(result.error);
        setStep(CheckTicketStep.error);
      } else {
        setStep(CheckTicketStep.success);
        await sleep(1500);
        onComplete(result.ticket_jwt!);
      }
    },
    [jwt, onComplete]
  );

  useEffect(() => {
    console.log(text);
    console.log(textIsValid(text));
    if (textIsValid(text) && text?.length === TEXT_MAX_LENGTH) {
      setStep(CheckTicketStep.pin);
    }
  }, [text]);

  useEffect(() => {
    if (pin?.length === 4) {
      setStep(CheckTicketStep.check);
    }
  }, [pin]);

  useEffect(() => {
    switch (step) {
      case CheckTicketStep.check:
        validateTicket(text!, pin!);
        break;
      default:
        console.debug(`step: ${step}`);
    }
  }, [step, text, pin, validateTicket]);

  let contentHidden = step >= CheckTicketStep.check;
  return (
    <>
      <InfoMessage
        title="Verificare.."
        style={step !== CheckTicketStep.check ? { display: "none" } : {}}
      />
      <SuccessMessage
        title="Aveți o rotire gratuită"
        style={step !== CheckTicketStep.success ? { display: "none" } : {}}
      />
      <ErrorMessage
        title="Eroare verificare"
        subtitle={ticketError}
        style={step !== CheckTicketStep.error ? { display: "none" } : {}}
        onClose={() => {
          setStep(CheckTicketStep.code);
        }}
      />
      <div
        className={`inline-flex !w-[600px] flex-col items-center ${
          contentHidden ? "hidden" : ""
        }`}
      >
        <div className="form white">
          <div className="label mb-4 relative w-full">Verificare bilet</div>
          <div className="inline-flex flex-row mb-2 mt-6">
            <div
              onClick={() => {
                setStep(CheckTicketStep.code);
              }}
              className={`input mr-2 w-[350px]  ${
                step === CheckTicketStep.code ? " ring-2 ring-black " : ""
              }`}
            >
              {text !== undefined ? text : <span className="">-</span>}
            </div>
            <div
              onClick={() => {
                setStep(CheckTicketStep.pin);
              }}
              className={`input w-[120px] ${
                step === CheckTicketStep.pin ? " ring-2 ring-black " : ""
              }`}
            >
              {(pin ?? "").length !== 0 ? (
                pin
              ) : (
                <span className="w-full text-center">----</span>
              )}
            </div>
          </div>
          <div className={`comment mt-1`}>{message}</div>
        </div>

        {step === CheckTicketStep.code && (
          <div className="form dark" style={{ marginTop: 24, width: "860px" }}>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FullKeyboard
                initialValue={undefined}
                maxLength={4}
                onPress={handleFullKeyboardKey}
                onBackspace={handleFullKeyboardBackspace}
                title=""
              />

              {/* FIXME: decide Font based on brand */}
              <div
                className={`button w-64 mt-8 font-casa ${
                  textIsValid(text) ? "" : "disabled"
                }`}
                onClick={handleNext}
              >
                Continuă
              </div>
            </div>
          </div>
        )}
        {step === CheckTicketStep.pin && (
          <div className="form dark" style={{ marginTop: 24, width: "400px" }}>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <NumericKeyboard
                key={"numk"}
                initialValue={undefined}
                maxLength={4}
                onChange={handleChangeNumKeyboard}
                title=""
              />

              {/* FIXME: decide Font based on brand */}
              <div className="button w-64 mt-8 font-casa" onClick={handleNext}>
                Continuă
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CheckSBLottoTicket;
