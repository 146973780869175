import { ReactComponent as Show } from "../img/visibility_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Hide } from "../img/visibility_off_FILL0_wght400_GRAD0_opsz24.svg";
const ToggleVisibilityButton: React.FC<{
  visible: boolean;
  onClick: () => void;
}> = ({ visible, onClick }) => {
  return (
    <div className="inline-flex items-center content-center" onClick={onClick}>
      {visible === true && <Show className="inline-block fill-inherit" />}
      {visible === false && <Hide className="inline-block fill-inherit" />}
    </div>
  );
};
export default ToggleVisibilityButton;
