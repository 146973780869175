import React from "react";
import heart_hands from "../img/icons8-heart-hands-94.png";
import close_button from "../img/close_round_x.png";
import useAppStore from "../data/store";

const GameResult: React.FC<{
  onClose: () => void;
}> = (props) => {
  const { onClose } = props;
  const brand = useAppStore((state) => state.brand);
  return (
    <div
      style={{
        fontFamily: `${brand === "casa" ? "Anton" : "Open Sans"}`,
        fontWeight: `${brand === "casa" ? "400" : "800"}`,
        fontSize: "52px",
        color: "#232323",
        padding: "8px",
        boxShadow: "rgba(255, 236, 0, 0.5) 0px -4px 45px",
        position: "relative",
        borderRadius: "24px",
        background: "#FFEC00",
      }}
    >
      <div
        style={{
          border: "8px solid #FFEC00",
          padding: "72px 96px",
          borderRadius: 20,
          display: "inline-flex",
          justifyContent: "center",
          boxShadow:
            "rgba(0,0,0,0.05) 0px 0px 50px inset, rgba(0,0,0,0.1) 0px 0px 16px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              textTransform: brand === "fortuna" ? "uppercase" : "none",
            }}
          >
            Mai mult noroc data viitoare!
            <img
              style={{
                marginLeft: 16,
                marginBottom: 8,
                display: "inline-block",
              }}
              src={heart_hands}
              alt="heart-hands"
            />
          </div>
          <div
            style={{
              display: "inline-block",
              fontSize: 22,
              fontFamily: "Open Sans",
              paddingTop: "24px",
              fontWeight: 400,
            }}
          >
            <br></br>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "inline-block",
          width: 72,
          height: 72,
          position: "absolute",
          bottom: -36,
          right: "calc(50% - 36px)",
        }}
      >
        <img onClick={onClose} src={close_button} alt="close" width="100%" />
      </div>
    </div>
  );
};

export default GameResult;
