import logo from "../img/CasaLogo.png";
import logo2x from "../img/CasaLogo@2x.png";
function HeaderCasa() {
  return (
    <div
      style={{
        background: "#ED1C24",
        height: "190px",
        width: "100%",
        position: "fixed",
        display: "block",
      }}
    >
      <div
        style={{
          fontSize: 60,
          color: "white",

          position: "absolute",
          top: 40,
          left: 40,
          fontFamily: "Anton",
        }}
      >
        Roata Norocului
      </div>
      <div style={{ position: "absolute", top: 40, right: 40 }}>
        <img src={logo} alt="logo" srcSet={`${logo2x} 2x`} />
      </div>
    </div>
  );
}
export default HeaderCasa;
