import { useEffect, useState } from "react";
import useAppStore from "../data/store";
import AskTicketType from "./AskTicketType";
import CheckSBLottoTicket from "./CheckSBLottoTicket";
import CheckVirtualsTicket from "./CheckVirtualsTicket";

export enum TicketType {
  sb_lotto,
  virtuals,
}

function CheckTicket({
  onComplete,
  jwt,
  user,
}: {
  jwt: string;
  user: string;
  onComplete: (token: string) => void;
}) {
  const [type, setType] = useState<TicketType | undefined>(undefined);
  const brand = useAppStore((state) => state.brand);
  useEffect(() => {
    console.log(`ct: ${brand}`);
  }, [brand]);
  return (
    <div className={`fullscreen modal app_background_${brand} `}>
      {type === undefined && (
        <AskTicketType
          onComplete={(type) => {
            setType(type);
          }}
        />
      )}
      {type === TicketType.sb_lotto && (
        <CheckSBLottoTicket onComplete={onComplete} jwt={jwt} user={user} />
      )}
      {type === TicketType.virtuals && (
        <CheckVirtualsTicket onComplete={onComplete} jwt={jwt} user={user} />
      )}
    </div>
  );
}
export default CheckTicket;

export enum CheckTicketMessages {
  sblotto = "Codul scurt are 6 litere si cifre. Numărul biletului are 16 cifre.",
  pin = "Pin-ul (codul de verificare) are 4 cifre",
}

// Interface to decide the type of ticket to check
