import { useCallback, useEffect, useRef, useState } from "react";
const url_check_cnp = process.env.REACT_APP_URL_CNP!;
const url_game_api = process.env.REACT_APP_GAME!;
const url_ticket_api = process.env.REACT_APP_TICKET!;

export async function serverCheckTicket(
  number: string,
  code: string,
  jwt: string
): Promise<{ ticket_jwt?: string | undefined; error?: string | undefined }> {
  try {
    const controller = new AbortController();
    const signal = controller.signal;
    setTimeout(() => controller.abort(), 5000);

    const result = await fetch(`${url_ticket_api}sb/check`, {
      body: JSON.stringify({
        ticketId: number,
        protectionCode: code,
        jwt,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      signal,
      method: "POST",
    });

    let json = await result.json();

    console.debug(json);
    const { jwt: ticket_jwt, error } = json;
    if (error) {
      throw error;
    }
    return { ticket_jwt };
  } catch (error) {
    console.debug("servercheckTicket ERROR");
    console.debug(error);
    switch (error) {
      case "TICKET_NOT_FOUND":
        return { error: "Biletul nu a fost gasit" };
      case "TICKET_EXPIRED":
        return { error: "Biletul nu este unul recent." };
      case "TICKET_ALREADY_USED":
        return { error: "Biletul a fost folosit deja." };
      default:
        return { error: "Verificare nereusita. Incercati mai tarziu." };
    }
  }
}

export async function serverCheckVirtualsTicket(
  number: string,

  jwt: string
): Promise<{ ticket_jwt?: string | undefined; error?: string | undefined }> {
  try {
    const controller = new AbortController();
    const signal = controller.signal;
    setTimeout(() => controller.abort(), 5000);

    const result = await fetch(`${url_ticket_api}virtuals/check`, {
      body: JSON.stringify({
        ticketId: number,
        jwt,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      signal,
      method: "POST",
    });

    let json = await result.json();

    console.debug(json);
    const { jwt: ticket_jwt, error } = json;
    if (error) {
      throw error;
    }
    return { ticket_jwt };
  } catch (error) {
    console.debug("servercheckTicket ERROR");
    console.debug(error);
    switch (error) {
      case "TICKET_NOT_FOUND":
        return { error: "Biletul nu a fost gasit" };
      case "TICKET_EXPIRED":
        return { error: "Biletul nu este unul recent." };
      case "TICKET_ALREADY_USED":
        return { error: "Biletul a fost folosit deja." };
      default:
        return { error: "Verificare nereusita. Incercati mai tarziu." };
    }
  }
}

export async function checkCNP(
  cnp: string,
  brand_id: string,
  ak: string
): Promise<{ result: number; d?: string; user?: string }> {
  try {
    let params = new URLSearchParams();
    params.append(`cnp`, `${cnp}`);
    params.append(`brand`, brand_id);
    params.append(`apikey`, `${ak}`);
    let q = params.toString();
    console.log(q);

    const result = await fetch(`${url_check_cnp}?${q}`, {
      body: "",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    let json = await result.json();
    console.debug(json);
    const { data, error } = json;
    if (error) {
      return { result: -100, d: "cnp error" };
    }

    if (!data) {
      return { result: 0, d: "user not registered" };
    }

    if (data?.active_restrictions_count > 0) {
      return { result: -1, d: "user is restricted" };
    }

    if (data?.active_restrictions_count === 0) {
      return { result: 1, user: `${data.rr_id}` };
    }
  } catch (error) {
    return { result: -200, d: "probably request error, try again" };
  }
  return { result: -200, d: "probably request error, try again" };
}

export async function checkRNUser(
  id: string,
  jwt: string
): Promise<{ result: number; jwt?: string | undefined; end?: Date }> {
  try {
    const checkResult = await fetch(`${url_game_api}check_user`, {
      body: JSON.stringify({ user: id, jwt }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    let json = await checkResult.json();
    console.debug(json);
    const { result, jwt: new_token, error } = json;
    if (error) {
      return { result: -1 };
    }
    return { result, jwt: new_token, end: new Date(json.end) };
  } catch (err) {
    console.debug(err);
    return { result: -1 };
  }
}

export async function spinWheel(jwt: string) {
  const url_spin = `${url_game_api}spin`;
  try {
    const result = await fetch(url_spin, {
      body: JSON.stringify({
        jwt,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    console.log("got spin result");
    const json = await result.json();
    console.log(`got spin json`);
    console.debug(json);
    return json;
  } catch (err) {
    console.error(`spinWheel catch ${err}`);
    return { error: { rncode: "RN999", desc: "Probleme de conexiune" } };
  }
}

export function useAuthorization() {
  const [data, setData] = useState<
    | {
        prizes: { i: string; label: string }[];
        settings: { [key: string]: any; ticket: { check: boolean } };
        shop_id: string;
        shop_code: string;
        brand_id: string;
        ak: string;
        token: string;
      }
    | undefined
  >();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const feg_data = useRef(undefined);
  const retry = useRef(0);
  const effectCalled = useRef(false);

  const tryFetch = useCallback(async () => {
    console.log("useAuthorization tryFetch");
    const url_feg_authorization = process.env.REACT_APP_AUTHORIZATION!;
    const url_wof_registration = process.env.REACT_APP_REGISTRATION!;
    console.log(url_feg_authorization);
    console.log(url_wof_registration);
    setLoading(true);
    try {
      const feg_result = await fetch(url_feg_authorization);
      console.log("got feg result");
      const feg_json = await feg_result.json();
      console.log(`got feg json`);
      //   console.debug(feg_json);
      feg_data.current = feg_json;
      const wof_result = await fetch(url_wof_registration, {
        body: JSON.stringify({ feg_json }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      const wof_json = await wof_result.json();
      //   console.debug(wof_json);
      if (wof_json.error) {
        console.debug("useAuthorization: ERROR");
        throw new Error(wof_json.error);
      } else {
        console.debug("useAuthorization: all good");
        setData({
          prizes: wof_json.prizes,
          settings: wof_json.settings,
          shop_id: wof_json.shop_id,
          shop_code: wof_json.shop_code,
          brand_id: wof_json.brand_id,
          token: wof_json.token,
          ak: wof_json.ak,
        });
        setError(undefined);
        setLoading(false);
      }
    } catch (err) {
      console.error(`useAuthorization catch ${err}`);
      setData(undefined);
      setError(`${err}`);
      retry.current += 1;
      setTimeout(async () => {
        await tryFetch();
      }, Math.min(Math.pow(2, retry.current), 64) * 1000);
    }
  }, []);

  useEffect(() => {
    if (effectCalled.current) {
      return console.log("useAuthorization already running");
    }
    console.log("useAuthorization");
    effectCalled.current = true;

    tryFetch();
  }, [tryFetch]);

  return { data, error, loading };
}
