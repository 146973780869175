let DEBUG_MODE = false;
let debug = DEBUG_MODE ? console.debug : () => {};

function isCNP(string) {
  let cnp = `${string}`;
  // check length
  if (cnp.length !== 13) {
    debug("wrong length");
    return false;
  }

  // check if all digits are numbers
  for (let i = 0; i < 12; i++) {
    if (isNaN(cnp[i])) {
      debug("CNP is not a number");
      return false;
    }
  }

  let checksum_digit = checksum(cnp);
  if (cnp[12] !== `${checksum_digit}`) {
    debug("invalid checksum digit");
    return false;
  }

  debug(`${cnp} is a valid CNP`);
  return true;
}

/* Compute the 13th digit of the CNP
 * String can be 12 or 13 digits.
 * We take first 12 digits of the CNP and 
 multiply each digit with its corresponding value from a MAGIC string
 * We divide the sum of these products by 11 and.
 * Return the reminder if it's < 10, or return 1 if reminder is 10.
*/
function checksum(string) {
  let cnp = string;
  const MAGIC = "279146358279";
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += Number(cnp[i]) * Number(MAGIC[i]);
  }
  let reminder = sum % 11;
  return reminder === 10 ? 1 : reminder;
}

module.exports = isCNP;
