import { TicketType } from "./CheckTicket";
import InfoMessage from "./InfoMessage";

function AskTicketType({
  onComplete,
}: {
  onComplete: (type: TicketType) => void;
}) {
  return (
    <InfoMessage title="Verificare..">
      <div className="mb-4">
        <div className="m-4">Alege tipul biletului</div>

        <div
          className="button w-64 mr-2"
          onClick={() => {
            onComplete(TicketType.sb_lotto);
          }}
        >
          Pariuri sportive
        </div>
        <div
          className="button w-64 mr-2"
          onClick={() => {
            onComplete(TicketType.sb_lotto);
          }}
        >
          Loto
        </div>
        <div
          className="button w-64"
          onClick={() => {
            onComplete(TicketType.virtuals);
          }}
        >
          Virtuale
        </div>
      </div>
    </InfoMessage>
  );
}

export default AskTicketType;
