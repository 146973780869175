import { create } from "zustand";

interface AppState {
  brand: "casa" | "fortuna" | "nobrand";
  setBrand: (brand: "casa" | "fortuna" | "nobrand") => void;
}

const useAppStore = create<AppState>((set) => ({
  brand: "nobrand",
  setBrand: (brand: "casa" | "fortuna" | "nobrand") => set({ brand: brand }),
}));

export default useAppStore;
