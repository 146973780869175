import React from "react";
import confetti from "../img/icons8-confetti-94.png";
import close_button from "../img/close_round_x.png";
import useAppStore from "../data/store";

const GameResult: React.FC<{
  prize: string;
  onClose: () => void;
}> = (props) => {
  const { prize, onClose } = props;
  const brand = useAppStore((state) => state.brand);
  return (
    <div
      style={{
        fontFamily: `${brand === "casa" ? "Anton" : "Open Sans"}`,
        fontWeight: `${brand === "casa" ? "400" : "800"}`,
        fontSize: "52px",
        color: "#232323",
        padding: "8px",
        boxShadow: "0px -4px 45px #00E06873",
        position: "relative",
        borderRadius: "24px",
        background: "#00E068",
      }}
    >
      <div
        style={{
          border: "8px solid #00E068",
          padding: "72px 96px",
          borderRadius: 20,
          display: "inline-flex",
          justifyContent: "center",
          boxShadow: "rgba(0,0,0,0.05) 0px 0px 50px inset, rgba(0,0,0,0.1) 0px 0px 16px",
        }}>
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "inline-block" }}>
            FELICITĂRI!{" "}
            <img
              style={{ display: "inline-block" }}
              src={confetti}
              alt="confetti"
            />
          </div>
          <div
            style={{
              display: "inline-block",
              textTransform: brand === "fortuna" ? "uppercase" : "none",
            }}
          >
            Ai câștigat: <u>{prize}!</u>
          </div>
          <div
            style={{
              display: "inline-block",
              fontSize: 22,
              fontFamily: "Open Sans",
              fontWeight: 400,
              paddingTop: "24px",
            }}
          >
            Află la casierie cum îți poți ridica premiul.
          </div>
        </div>
      </div>
      <div
        style={{
          display: "inline-block",
          width: 72,
          height: 72,
          position: "absolute",
          bottom: -36,
          right: "calc(50% - 36px)",
        }}
      >
        <img onClick={onClose} src={close_button} alt="close" width="100%" />
      </div>
    </div>
  );
};

export default GameResult;
