import { useCallback, useEffect, useState } from "react";
import FullKeyboard from "../components/FullKeyboard";

import { sleep } from "../components/util";
import { serverCheckVirtualsTicket } from "../data/api";

import ErrorMessage from "./ErrorMessage";
import InfoMessage from "./InfoMessage";
import SuccessMessage from "./SuccessMessage";
enum CheckTicketStep {
  code,
  check,
  success,
  error,
}
function CheckVirtualsTicket({
  onComplete,
  jwt,
}: {
  onComplete: (token: string) => void;
  jwt: string;
  user: string;
}) {
  const [text, setText] = useState<string | undefined>("");
  const [message] = useState<string>("9 litere si cifre");
  const [step, setStep] = useState<CheckTicketStep>(CheckTicketStep.code);
  const [ticketError, setTicketError] = useState<string>("");
  const TEXT_MAX_LENGTH = 9;

  const handleFullKeyboardKey = useCallback(
    (key: string) => {
      setText((text) =>
        (text ?? "").length < TEXT_MAX_LENGTH ? `${text ?? ""}${key}` : text
      );
    },
    [setText]
  );

  const handleFullKeyboardBackspace = useCallback(() => {
    setText((text) =>
      (text ?? "").length > 0 ? text?.slice(0, text.length - 1) : undefined
    );
  }, []);

  const textIsValid = (text: string | undefined) => {
    let length = text?.length;
    if (length === 9) {
      return true;
    } else {
      return false;
    }
  };
  const handleNext = useCallback(() => {
    setStep(CheckTicketStep.check);
  }, []);

  // Check the ticket on server and receive a JWT to use on spin.
  const validateTicket = useCallback(
    async (text: string) => {
      let result = await serverCheckVirtualsTicket(text, jwt);
      if (result.error !== undefined) {
        setTicketError(result.error);
        setStep(CheckTicketStep.error);
      } else {
        setStep(CheckTicketStep.success);
        await sleep(1500);
        onComplete(result.ticket_jwt!);
      }
    },
    [jwt, onComplete]
  );

  useEffect(() => {
    console.log(text);
    console.log(textIsValid(text));
    if (textIsValid(text) && text?.length === TEXT_MAX_LENGTH) {
      setStep(CheckTicketStep.check);
    }
  }, [text]);

  useEffect(() => {
    switch (step) {
      case CheckTicketStep.check:
        validateTicket(text!);
        break;
      default:
        console.debug(`step: ${step}`);
    }
  }, [step, text, validateTicket]);

  let contentHidden = step >= CheckTicketStep.check;
  return (
    <>
      <InfoMessage
        title="Verificare.."
        style={step !== CheckTicketStep.check ? { display: "none" } : {}}
      />
      <SuccessMessage
        title="Aveți o rotire gratuită"
        style={step !== CheckTicketStep.success ? { display: "none" } : {}}
      />
      <ErrorMessage
        title="Eroare verificare"
        subtitle={ticketError}
        style={step !== CheckTicketStep.error ? { display: "none" } : {}}
        onClose={() => {
          setStep(CheckTicketStep.code);
        }}
      />
      <div
        className={`inline-flex !w-[600px] flex-col items-center ${
          contentHidden ? "hidden" : ""
        }`}
      >
        <div className="form white">
          <div className="label mb-4 relative w-full">Verificare bilet</div>
          <div className="inline-flex flex-row mb-2 mt-6">
            <div
              onClick={() => {
                setStep(CheckTicketStep.code);
              }}
              className={`input mr-2 w-[350px]  ${
                step === CheckTicketStep.code ? " ring-2 ring-black " : ""
              }`}
            >
              {text !== undefined ? text : <span className="">-</span>}
            </div>
          </div>
          <div className={`comment mt-1`}>{message}</div>
        </div>

        {step === CheckTicketStep.code && (
          <div className="form dark" style={{ marginTop: 24, width: "860px" }}>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FullKeyboard
                initialValue={undefined}
                maxLength={4}
                onPress={handleFullKeyboardKey}
                onBackspace={handleFullKeyboardBackspace}
                title=""
                caps={true}
              />

              {/* FIXME: decide Font based on brand */}
              <div
                className={`button w-64 mt-8 font-casa ${
                  textIsValid(text) ? "" : "disabled"
                }`}
                onClick={handleNext}
              >
                Continuă
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CheckVirtualsTicket;
