import React, { useCallback, useEffect, useRef } from "react";
// import { ReactComponent as WheelCenter } from "./Wheel-center.svg";
import casa_center_img from "../img/casa-center.png";
import fortuna_center_img from "../img/ftn-center.png";

// import pin from "../img/Union 1.png";
import ftn_pin from "../img/new-ftn-pin.png";
import casa_pin from "../img/new-casa-pin.png";

import ftn_cover from "../img/new-ftn-cover.png";
import casa_cover from "../img/new-casa-cover.png";

import useAppStore from "../data/store";

export enum WheelMode {
  stopped,
  idle,
  started,
}

interface WheelProps {
  items: string[];
  mode: WheelMode;
  winningFn: () => number | undefined;
  onComplete: (halted: boolean) => void;
}

const Pin = {
  casa: casa_pin,
  fortuna: ftn_pin,
  nobrand: ftn_pin,
};

const Cover = {
  casa: casa_cover,
  fortuna: ftn_cover,
  nobrand: ftn_cover,
};
const CenterImg = {
  casa: casa_center_img,
  fortuna: fortuna_center_img,
  nobrand: fortuna_center_img,
};

function makeColorsArray(brand: "casa" | "fortuna" | "nobrand", size: number) {
  let COLORS = {
    casa: [
      { b: "#ffec00", l: "black" },
      { b: "#ED1C24", l: "white" },
      { b: "#FFFFFF", l: "black" },
    ],
    fortuna: [
      { b: "#FFDB01", l: "black" },
      { b: "#000000", l: "white" },
      { b: "#FFFFFF", l: "black" },
    ],
    nobrand: [
      { b: "#ffec00", l: "black" },
      { b: "#ED1C24", l: "white" },
      { b: "#FFFFFF", l: "black" },
    ],
  };

  const colors: { b: string; l: string }[] = new Array(size)
    .fill(0)
    .map((_, i) => (i % 2 === 0 ? COLORS[brand][0] : COLORS[brand][1]));
  colors[0] = COLORS[brand][2];
  return colors;
}

function Wheel(props: WheelProps) {
  //   const { items } = props
  const { items, mode, winningFn, onComplete } = props;
  const brand = useAppStore((state) => state.brand);

  const wheel = useRef<HTMLDivElement>(null);
  const dots = useRef<HTMLDivElement>(null);
  const pinRef = useRef<HTMLImageElement>(null);

  let slices = items.length;
  let angle = (2 * Math.PI) / slices;
  let angleDeg = 360 / slices;
  let wheel_width = 600;
  let slice_width = wheel_width * Math.tan(angle / 2);

  function idleSpinning() {
    const animation = [
      { transform: `rotate(0deg)` },
      { transform: `rotate(360deg)` },
    ];
    const animationOptions: KeyframeAnimationOptions = {
      duration: 360 * 60,
      iterations: Infinity,
      easing: "linear",
      fill: "forwards",
    };
    wheel.current?.animate(animation, animationOptions);
    dots.current?.animate(animation, animationOptions);
  }
  // spin ending on the winning
  const spinForResult = useCallback(
    (winning: number) => {
      console.debug(`spinForResult`);
      const startAnimationTargetAngle = 360;
      let targetAngles = [
        (winning * angle * 180) / Math.PI,
        ((winning + 1) * angle * 180) / Math.PI,
      ];

      const animationEndAngle = Math.floor(
        startAnimationTargetAngle +
          360 * 10 +
          0.5 * angleDeg -
          (targetAngles[1] - Math.random() * angleDeg * 0.84 - angleDeg * 0.08)
      );
      console.log(
        `winning: ${winning} animationEndAngle: ${animationEndAngle}deg`
      );

      const winAnimation = [
        { transform: `rotate(${startAnimationTargetAngle}deg)` },
        { transform: `rotate(${animationEndAngle}deg)` },
      ];
      const winAnimationOption: KeyframeAnimationOptions = {
        duration: 12000,
        easing: "cubic-bezier(0,0,0,1)",
        fill: "forwards",
      };
      const a3 = wheel.current?.animate(winAnimation, winAnimationOption);
      const a4 = dots.current?.animate(winAnimation, winAnimationOption);
      a4!.onfinish = () => {
        a4!.commitStyles();
      };
      a3!.onfinish = () => {
        a3?.commitStyles();
        setTimeout(() => {
          onComplete(false);
        }, 20);
      };
    },
    [onComplete, angle, angleDeg]
  );

  //
  const spinWaitingResult = useCallback(
    (counter: number = 0) => {
      console.debug(`spinWaitingResult a1 counter: ${counter}`);
      let startAnimationTargetAngle = 360 * 4 * (counter + 1);
      const startAnimation = [
        { transform: `rotate(${360 * 4 * counter}deg)` },
        { transform: `rotate(${startAnimationTargetAngle}deg)` },
      ];
      const startAnimationOptions: KeyframeAnimationOptions = {
        duration: 2000,
        easing: "cubic-bezier(.8,0,1,1)",
        fill: "forwards",
      };

      const loopAnimationOptions: KeyframeAnimationOptions = {
        duration: 1000,
        easing: "linear",
        fill: "forwards",
      };

      let a1 = wheel.current?.animate(
        startAnimation,
        counter === 0 ? startAnimationOptions : loopAnimationOptions
      );
      let a2 = dots.current?.animate(
        startAnimation,
        counter === 0 ? startAnimationOptions : loopAnimationOptions
      );
      a1!.onfinish = () => {
        a1?.commitStyles();
        a2?.commitStyles();
        const winning = winningFn();
        console.debug(`a1 finished ${winning} counter: ${counter}`);
        if (winning === undefined) {
          if (counter < 15) {
            spinWaitingResult(counter + 1);
            return;
          }
          const haltAnimation = [
            { transform: `rotate(${360}deg)` },
            { transform: `rotate(0)` },
          ];
          const haltAnimationOption: KeyframeAnimationOptions = {
            duration: 1000,
            easing: "linear",
            fill: "both",
            iterations: Infinity,
          };
          wheel.current?.animate(haltAnimation, haltAnimationOption);
          dots.current?.animate(haltAnimation, haltAnimationOption);
          // h1!.onfinish = () => {
          //   console.log("Halt animation finished");
          setTimeout(() => {
            onComplete(true);
          }, 1000);
          // };
          return;
        } else {
          spinForResult(winning);
        }
      };
    },
    [onComplete, winningFn, spinForResult]
  );

  const startSpinning = useCallback(() => {
    spinWaitingResult();
  }, [spinWaitingResult]);

  const colors = makeColorsArray(brand, items.length);
  const cover = Cover[brand];
  const pin = Pin[brand];
  const center_img = CenterImg[brand];
  // const pin_rotate_deg = find_pin_angle(wheelRotation, angleDeg, slice_width);

  useEffect(() => {
    console.log(`useEffect wheel mode ${mode}`);
    switch (mode) {
      case WheelMode.stopped: {
        // stop all animations
        wheel.current?.getAnimations()?.map((a) => a.cancel());
        dots.current?.getAnimations()?.map((a) => a.cancel());
        break;
      }
      case WheelMode.idle: {
        wheel.current?.getAnimations()?.map((a) => a.cancel());
        dots.current?.getAnimations()?.map((a) => a.cancel());
        idleSpinning();
        break;
      }
      case WheelMode.started: {
        wheel.current?.getAnimations()?.map((a) => a.cancel());
        dots.current?.getAnimations()?.map((a) => a.cancel());
        startSpinning();
        break;
      }
    }
  }, [mode, startSpinning]);

  return (
    <div
      style={{
        position: "relative",
        width: `${wheel_width + 100}px`,
        height: `${wheel_width + 100}px`,
        willChange: "transform",
        top: 0,
      }}
    >
      <div
        ref={wheel}
        style={{
          position: "relative",
          left: 50,
          top: 50,
          width: `${wheel_width}px`,
          height: `${wheel_width}px`,

          borderRadius: "50%",
          overflow: "hidden",
          // transform: `translateZ(0px) rotate(${wheelRotation}deg)`,
          willChange: "transform",
        }}
      >
        {items.map((v, i) => (
          <Slice
            key={`${i} ${v}`}
            radius={wheel_width / 2}
            text={v}
            width={(slice_width / wheel_width) * 100}
            rotation={((angle * 360) / Math.PI / 2) * i}
            color={colors[i].b}
            textColor={colors[i].l}
            index={i}
          />
        ))}
      </div>
      <img
        src={cover}
        alt="center"
        style={{
          position: "absolute",
          width: wheel_width + 172,
          height: wheel_width + 172,
          left: -25,
          top: -20,
          zIndex: 3000,
        }}
      />

      <div
        ref={dots}
        style={{
          position: "absolute",
          zIndex: 3500,
          left: 40,
          top: 40,
          width: wheel_width + 20,
          height: wheel_width + 20,
          display: "inline-block",
          // transform: `translateZ(0px) rotate(${wheelRotation}deg)`,
          willChange: "transform",
        }}
      >
        {items.map((v, i) => (
          <div
            key={`dot ${i}`}
            style={{
              display: "inline-block",
              position: "absolute",
              backgroundColor: "white",
              borderRadius: "50%",
              boxShadow: "0px 0px 10px 10px #ffffff3e",
              width: 20,
              height: 20,
              left:
                wheel_width * 0.5 +
                wheel_width * 0.5 * Math.cos(-Math.PI / 2 + angle * i),
              top:
                wheel_width * 0.5 +
                wheel_width * 0.5 * Math.sin(-Math.PI / 2 + angle * i),
              zIndex: 3500 + i,
            }}
          />
        ))}
      </div>
      <img
        src={pin}
        ref={pinRef}
        alt="pin"
        style={{
          position: "absolute",
          width: 233 * 0.75,
          height: 188 * 0.75,
          left: 263, // wheel_width * 0.5 - 44,
          top: -56,
          zIndex: 4002,
          //  transform: `rotate(${pin_rotate_deg}deg)`,
          transformOrigin: `${40}px ${41}px`,
        }}
      />
      <img
        src={center_img}
        alt="center"
        style={{
          position: "absolute",
          width: 140,
          height: 140,
          left: wheel_width / 2 + 50 - 70,
          top: wheel_width / 2 + 50 - 70,
          zIndex: 3000,
        }}
      />
    </div>
  );
}
export default Wheel;

interface SliceProps {
  radius: number;
  width: number;
  rotation: number;
  color: string;
  text: string;
  textColor: string;
  index: number;
}
function Slice({
  width,
  rotation,
  color,
  text,
  textColor,
  index,
  radius,
}: SliceProps) {
  let clip = [50 - width / 2, 50 + width / 2];
  let rotationAngle = rotation;
  const brand = useAppStore((state) => state.brand);
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        // filter: "drop-shadow(0px 0px 10px white)",
        zIndex: 100 + index,
      }}
    >
      <div
        style={{
          backgroundColor: color,
          clipPath: `polygon(${clip[0]}% 0%, ${clip[1]}% 0%, 50% 50%)`,
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          position: "absolute",
          left: 0,
          top: 0,
          display: "inline-flex",
          alignItems: "flex-start",
          transform: `rotate(${rotationAngle}deg)`,
        }}
      >
        <div
          style={{
            paddingTop: "30px",
            paddingRight: "4px",
            fontFamily: `${brand === "casa" ? "Anton" : "Open Sans"}`,
            textTransform: `${brand === "casa" ? "none" : "uppercase"}`,
            fontSize: `${brand === "casa" ? "24px" : "21px"}`,
            fontWeight: `${brand === "casa" ? "400" : "800"}`,
            lineHeight: `${brand === "casa" ? "24px" : "21px"}`,
            textAlign: "left",

            zIndex: 20,
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
            height: "40%",
            color: textColor,
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
}

// function find_pin_angle(
//   wheelRotation: number,
//   angleDeg: number,
//   slice_width: number
// ) {
//   const delta_from_anchor = wheelRotation % angleDeg;
//   const delta_offset = slice_width * (1 - delta_from_anchor / angleDeg);
//   const MIN = 40;
//   const MAX = 120;
//   const MID = 60;
//   const MID2 = 90;
//   const MAX_ANGLE = 60;
//   if (delta_offset < MIN) {
//     return 0;
//   }

//   if (delta_offset > MAX) {
//     return 0;
//   }

//   if (delta_offset > MID && delta_offset < MID2) {
//     return -MAX_ANGLE;
//   }

//   if (delta_offset < MID) {
//     return -(1 - (MID - delta_offset) / (MID - MIN)) * MAX_ANGLE;
//   } else {
//     return -(1 - (delta_offset - MID2) / (MAX - MID2)) * MAX_ANGLE;
//   }
// }
