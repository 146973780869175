import { ReactComponent as Logo } from "../img/Fortuna-logo1.svg";
// import logo2x from "../img/Fortuna-logo1@2x.png";
function HeaderFortuna() {
  return (
    <div
      style={{
        background: "#ffdb01",
        height: "320px",
        width: "100%",
        position: "fixed",
        display: "block",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: 0,
          height: 0,
          top: 140,
          borderBottom: "180px solid black",
          borderLeft: "1920px solid transparent",
        }}
      />
      <div
        style={{
          color: "#000000",
          transform: "skewY(-6deg)",
          textTransform: "uppercase",
          fontFamily: "Open Sans",
          fontSize: "76px",
          lineHeight: "85px",
          fontWeight: 800,

          position: "absolute",
          top: 60,
          left: 40,
        }}
      >
        <span>Roata</span>
        <br />
        <span> Norocului</span>
      </div>
      <div style={{ position: "absolute", top: 40, right: 40 }}>
        {/* <img src={logo} alt="logo" srcSet={`${logo2x} 2x`} /> */}
        <Logo />
      </div>
    </div>
  );
}
export default HeaderFortuna;
