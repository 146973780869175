import React, { useCallback, useEffect, useRef, useState } from "react";
import SpinButton from "../components/SpinButton";
import Wheel, { WheelMode } from "../components/Wheel";
import GameResultWinning from "./GameResultWinning";
import GameResultLose from "./GameResultLose";
import { spinWheel } from "../data/api";
import ErrorMessage from "./ErrorMessage";
import CountdownMessage from "./CountdownMessage";
enum GameStatus {
  idle,
  ready,
  started,
  error,
  complete,
}

// const winning = Math.floor(Math.random() * items.length);
const Game: React.FC<{
  prizes: { i: string; label: string }[];
  onComplete: () => void;
  idle?: boolean;
  jwt: string;
}> = ({ prizes, onComplete, idle, jwt }) => {
  const [status, setStatus] = useState<GameStatus>(
    idle ? GameStatus.idle : GameStatus.ready
  );
  const winning = useRef<{ id: number; label: "string" } | undefined>(
    undefined
  );
  const winningError = useRef<{ rncode: string; desc: string } | undefined>(
    undefined
  );
  const haltedRef = useRef<boolean>(false);
  const winningFn = useCallback(() => winning.current?.id, []);
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    console.log(`Game status changed ${status}`);
  }, [status]);

  const onSpinComplete = useCallback((halted: boolean) => {
    console.log("GAME: ON SPIN WHEEL COMPLETE");

    if (halted === true) {
      console.log("onSpinComplete HALTED");
      haltedRef.current = true;
      winningError.current = { desc: "Probleme de conexiune", rncode: "RN999" };
      winning.current = undefined;
    }
    console.log(`winningError.current.rncode: ${winningError.current?.rncode}`);
    console.log(`winningError.current.desc: ${winningError.current?.desc}`);
    console.log(`winning.current._id: ${winning.current?.id}`);
    console.log(`winning.current.label: ${winning.current?.label}`);
    setStatus(GameStatus.complete);
  }, []);

  const getWinning = useCallback(async () => {
    let result = await spinWheel(jwt);
    console.log("got winning result");
    if (haltedRef.current === true) {
      console.log("winning came after game halted.");
      return;
    }
    if (result.spin_result) {
      winning.current = result.spin_result;
    } else {
      setEndDate(new Date(result.error.end) ?? new Date());
      winningError.current = result.error;
    }
  }, [jwt]);

  const startGame = useCallback(() => {
    if (idle) {
      onComplete();
      return;
    }
    getWinning();
    setStatus(GameStatus.started);
  }, [idle, onComplete, getWinning]);

  useEffect(() => {
    console.log("Game re-rendered");
  });

  let wheelMode = WheelMode.stopped;
  switch (status) {
    case GameStatus.idle:
      wheelMode = WheelMode.idle;
      break;
    case GameStatus.ready:
      wheelMode = WheelMode.stopped;
      break;
    case GameStatus.complete:
      wheelMode = WheelMode.started;
      break;
    case GameStatus.started:
      wheelMode = WheelMode.started;
      break;
    case GameStatus.error:
      wheelMode = WheelMode.stopped;
      break;
  }

  const showSpinButton = status !== GameStatus.complete;
  const enableSpinButton = status < 2;

  const handleKeyDown: (ev: KeyboardEvent) => void = useCallback(
    (ev) => {
      let key = ev.key;
      console.debug(key);
      if (key === "Enter") {
        if (enableSpinButton) {
          startGame();
        }
      }
    },
    [enableSpinButton, startGame]
  );
  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      handleKeyDown(e);
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [handleKeyDown]);

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          backgroundColor: "black",
          marginTop: "5vh",
          width: "90vh",
          height: "90vh",
          borderRadius: "50%",
          zIndex: 2,
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        <Wheel
          items={prizes.map((v) => v.label)}
          mode={wheelMode}
          winningFn={winningFn}
          onComplete={onSpinComplete}
        />
      </div>
      <div
        style={{
          display: "inline-block",
          position: "fixed",
          bottom: "20%",
          right: "20%",
          zIndex: 6000,
          userSelect: "none",
        }}
      >
        {showSpinButton && (
          <SpinButton
            onClick={() => {
              if (enableSpinButton) {
                startGame();
              }
            }}
          />
        )}
      </div>
      {status === GameStatus.complete && (
        <div
          className="fadein"
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 999999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {winningError.current?.rncode !== undefined &&
            winningError.current?.rncode !== "RN001" && (
              <ErrorMessage
                title="Probleme de conexiune"
                subtitle=""
                onClose={onComplete}
              />
            )}

          {winningError.current?.rncode !== undefined &&
            winningError.current?.rncode === "RN001" && (
              <CountdownMessage
                end={endDate}
                title="Timp rămas până la următoarea rotire"
                onClose={onComplete}
              />
            )}
          {winning.current !== undefined && winning.current.id > 0 && (
            <GameResultWinning
              prize={prizes[winning.current.id].label}
              onClose={onComplete}
            />
          )}
          {winning.current?.id === 0 && <GameResultLose onClose={onComplete} />}
        </div>
      )}
    </>
  );
};

export default Game;
